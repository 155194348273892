import { useState, useRef, useEffect } from "react";
import { initializeAOS } from "../integrations/aos-init";
import '../styles/styles.css';

const ProductsServicesSection = () => {
  useEffect(() => {
    initializeAOS(); // Inicializar AOS cuando el componente se monte
  }, []);

  const productsServices = [
    {
      icon: '📦',
      title: 'Soluciones ERP',
      description: 'Sistemas de planificación de recursos empresariales adaptados a las necesidades de tu industria.',
    },
    {
      icon: '👩‍💻',
      title: 'Control de Asistencia',
      description: 'Sistemas avanzados para el seguimiento y la gestión de la asistencia y el tiempo de los empleados.',
    },
    {
      icon: '🚚',
      title: 'Trazabilidad de Vehículos',
      description: 'Soluciones para el seguimiento y la gestión en tiempo real de toda la flota de vehículos.',
    },
    {
      icon: '📈',
      title: 'Trazabilidad de Materiales',
      description: 'Sistemas de seguimiento de extremo a extremo para materiales a lo largo de la cadena de suministro.',
    },
    {
      icon: '👨‍🔧',
      title: 'Trazabilidad de Mantenimiento',
      description: 'Soluciones completas para el seguimiento y la optimización de los procesos de mantenimiento.',
    },
    {
      icon: '💻',
      title: 'Servicios de Consultoría',
      description: 'Asesoría experta y soporte de implementación para todas nuestras soluciones.',
    },
  ];

  return (
    <section className="py-16 bg-gray-100 fondoCoffe">
      <div className="max-w-7xl mx-auto px-6 lg:px-8">
        <h2 className="text-3xl font-bold text-center text-gray-800 mb-10" data-aos="fade-up">
          Nuestros Productos y Servicios
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {productsServices.map((item, index) => (
            <div key={index} className="tarjetaProductsServices" data-aos="fade-up" data-aos-delay={`${index}00`}>
              <div className="tarjeta_link">
                <div className="tarjeta_bg"></div>
                <div className="tarjeta_title">
                  {item.title}
                </div>
                <div className="tarjeta_date-box">
                  {item.description}
                </div>
              </div>
              <img src="/images/icov-iso.png" alt="" className="tarjeta_img"/>
            </div>
          ))}
        </div>
      </div>
    </section >
  );
};

export default ProductsServicesSection;
